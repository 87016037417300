import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction } from "@remix-run/node"
import { Form, Link, useSearchParams } from "@remix-run/react"
import { useEffect, useMemo, useRef } from "react"
import { AuthorizationError } from "remix-auth"
import { SocialsProvider } from "remix-auth-socials"
import { typedjson, useTypedActionData, useTypedLoaderData } from "remix-typedjson"
import invariant from "tiny-invariant"

import GoogleLogo from "~/icons/GoogleLogo"
import { Information } from "~/icons/Information"
import {
  AuthEmailError,
  AuthLoginError,
  AuthPasswordError,
  redirectAuthenticatedUserCheckingConfirmation,
  authenticator,
  checkUserEmailConfirmedAndLogin,
  AuthInvitationError,
} from "~/modules/auth/auth.server"
import acceptInvitationForUser from "~/modules/invitations/acceptInvitationForUser"
import getAndClickInvitation from "~/modules/invitations/getAndClickInvitation"

export const loader = async ({ request }: LoaderFunctionArgs) => {
  await redirectAuthenticatedUserCheckingConfirmation(request)

  const searchParams = new URL(request.url).searchParams

  const invitation = await getAndClickInvitation(searchParams.get("invitation"))

  return typedjson({ invitation })
}

export const meta: MetaFunction = () => [{ title: "Login" }]

export default function LoginPage() {
  const { invitation } = useTypedLoaderData<typeof loader>()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get("redirectTo") || "/dashboard"
  const actionData = useTypedActionData<typeof action>()
  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const errorMessage = useMemo(
    () =>
      actionData?.errors?.email ||
      actionData?.errors?.invite ||
      actionData?.errors?.login ||
      actionData?.errors?.password,
    [actionData]
  )

  useEffect(() => {
    if (actionData?.errors?.email) {
      emailRef.current?.select()
      emailRef.current?.focus()
    } else if (actionData?.errors?.password || actionData?.errors?.login) {
      passwordRef.current?.select()
      passwordRef.current?.focus()
    }
  }, [actionData])

  return (
    <div className="flex flex-1 flex-col justify-center gap-y-7 py-3">
      <h2 className="mb-4 text-center text-2xl font-bold text-gray-900 md:text-2xl">Login</h2>

      <Form method="post" action={`/auth/${SocialsProvider.GOOGLE}`}>
        <input type="hidden" name="redirectTo" value={redirectTo} />

        <button className="flex h-11 w-full items-center justify-center gap-x-4 rounded-lg border-2 border-slate-300">
          <GoogleLogo />

          <p className="pb-0.5 text-slate-800">Login with Google</p>
        </button>
      </Form>

      <div className="relative flex items-center justify-center">
        <p className="z-10 bg-white px-2 pb-1 text-sm text-slate-400">or use your email</p>
        <div className="absolute z-0 w-full border-b border-slate-200"></div>
      </div>

      <Form method="post" className="flex w-full flex-col gap-y-6">
        <input type="hidden" name="invitationId" value={invitation?.id} />
        <input type="hidden" name="redirectTo" value={redirectTo} />

        <fieldset className="flex flex-col space-y-2">
          <input
            autoComplete="email"
            autoFocus={true}
            className="h-11 w-full rounded-lg border border-gray-300 px-3 text-base"
            defaultValue={invitation?.email}
            id="email"
            name="email"
            placeholder="Email address"
            ref={emailRef}
            required
            type="email"
          />

          <input
            autoComplete="current-password"
            className="h-11 w-full rounded-lg border border-gray-300 px-3 text-base"
            id="password"
            name="password"
            placeholder="Password"
            ref={passwordRef}
            type="password"
          />
        </fieldset>

        {errorMessage ? (
          <div className="flex flex-row items-center gap-x-2 rounded-lg text-red-700" role="alert">
            <Information />

            <p className="flex-1 font-bold">{errorMessage}.</p>
          </div>
        ) : null}

        <button type="submit" className="h-11 w-full rounded-lg bg-slate-800 text-white">
          Login with your email
        </button>
      </Form>

      <div className="relative flex items-center justify-center">
        <div className="absolute z-0 w-full border-b border-slate-200"></div>
      </div>

      <div className="text-md text-center text-slate-500">
        If you don&apos;t have an account:{" "}
        <Link
          className="text-link underline"
          to={{
            pathname: "/join",
            search: searchParams.toString(),
          }}
        >
          tap&nbsp;here&nbsp;to&nbsp;sign&nbsp;up
        </Link>
        .
      </div>
    </div>
  )
}

export const action = async ({ request }: ActionFunctionArgs) => {
  const errors: Record<"email" | "invite" | "login" | "password", string | undefined> = {
    email: undefined,
    invite: undefined,
    login: undefined,
    password: undefined,
  }
  const formData = await request.clone().formData()

  try {
    const userId = await authenticator.authenticate("username_and_password", request, {
      throwOnError: true,
    })

    invariant(userId)

    const invitationId = formData.get("invitationId")

    if (invitationId) {
      invariant(typeof invitationId === "string")

      await acceptInvitationForUser(userId, invitationId)
    }

    await checkUserEmailConfirmedAndLogin(request, userId)
  } catch (exception) {
    if (exception instanceof AuthorizationError) {
      if (exception instanceof AuthInvitationError) {
        errors.invite = exception.message
      } else if (exception.cause instanceof AuthEmailError) {
        errors.email = exception.message
      } else if (exception.cause instanceof AuthLoginError) {
        errors.login = exception.message
      } else if (exception.cause instanceof AuthPasswordError) {
        errors.password = exception.message
      } else {
        throw exception
      }
    } else {
      throw exception
    }
  }

  return typedjson({ errors })
}
